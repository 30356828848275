<template>
    <div class="add-new-travelling-order">
        <title-bar
            submitBtn
            :submitBtnTitle="$t('messages.stockItemLocationsUpdate')"
            :title="$t('messages.stockItemLocationsUpdate')"
            @submitPressed="submitPressed"
        />
        <storage-location-form v-if="storage" :storageObject="storage" :action="action" @clearAction="action = null"/>
    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import StorageLocationForm from './components/StorageLocationForm.vue'

export default {
    components: {
        TitleBar,
        StorageLocationForm,
    },
    data() {
        return {
            storage: null,
            action: null,
        };
    },
    created() {
        this.load();
    },
    methods: {
        load() {
            const id = this.$route.params.id;
            this.$StockItemLocations.getResource({id}).then((response) => {
                this.storage = response.data;
            });
        },
        submitPressed() {
            this.action = "update";
        },
    },
};
</script>
